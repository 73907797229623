import React, { useRef, useCallback } from "react"
import { Spherical, Vector3, MathUtils } from "three"
import { useFrame } from "@react-three/fiber"

function useCompass() {
   const compassRef = useRef<HTMLDivElement>()
   const CompassWorker = () => {
      const compassCallback = useCallback(v => {
         compassRef.current.style.transform = `rotate(${MathUtils.radToDeg(
            -v.theta + Math.PI * 0.5
         )}deg)`
      }, [])
      const dir = new Vector3()
      const sph = new Spherical()
      useFrame(({ camera }) => {
         camera.getWorldDirection(dir)
         sph.setFromVector3(dir)
         compassCallback(sph.setFromVector3(dir))
      })

      return null
   }

   function Compass({
      children,
      className
   }: {
      className?: string
      children: React.ReactNode
   }) {
      return (
         <div
            ref={compassRef}
            className={className}
            id="compass"
            style={{ pointerEvents: "none" }}
         >
            {children}
         </div>
      )
   }
   return {
      Compass,
      CompassWorker
   }
}

export default useCompass
