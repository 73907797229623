import { useState, useEffect } from "react";
import SphericalTour from "@components/Transition360/SphericalTour";
import Controls from "@components/Controls";
import ImageDisplay from "@components/ImageDisplay";
import Loading from "@components/Loading";
import Gallery from "@components/Gallery";

import { useStore } from "@state/store";
import srcArr from "@data/images";
import { cacheImages } from "./utils/cacheImages";

function App() {
  const loading = useStore((s) => s.loading);
  const setLoading = useStore((s) => s.setLoading);

  useEffect(() => {
    cacheImages(srcArr, () => setTimeout(() => setLoading(false), 800));
  }, []);
  return (
    <>
      <Controls />
      <ImageDisplay />
      <SphericalTour />
      <Gallery />
      <Loading loading={loading} />
    </>
  );
}

export default App;
