//@ts-ignore
const enviroment = process.env.NODE_ENV;
const baseUrl = () => {
  switch (enviroment) {
    case "development":
      return "http://172.24.159.63:5173";
    case "staging":
      return "https://americas-avenue-git-staging-vmiluna.vercel.app";
    case "test":
      return "https://d2nkuggqii9ikn.cloudfront.net";
    case "production":
      return "https://www.avenueoftheamericas.app";
    default:
      return;
  }
};

// console.log(process.env.NODE_ENV)
// const baseUrl = "http://192.168.0.122:5173"
const srcArr = [
  `${baseUrl()}/assets/15th/elevators.webp`,
  `${baseUrl()}/assets/15th/north.webp`,
  `${baseUrl()}/assets/15th/northEast.webp`,
  `${baseUrl()}/assets/15th/northWest.webp`,
  `${baseUrl()}/assets/15th/south.webp`,
  `${baseUrl()}/assets/15th/southEast.webp`,
  `${baseUrl()}/assets/15th/southWest.webp`,
  `${baseUrl()}/assets/catA/lounge.jpg`,
  `${baseUrl()}/assets/catA/officeNW.jpg`,
  `${baseUrl()}/assets/catA/officeSW.jpg`,
  `${baseUrl()}/assets/catA/pantry.jpg`,
  `${baseUrl()}/assets/catA/reception.jpg`,
  `${baseUrl()}/assets/creative/lounge.jpg`,
  `${baseUrl()}/assets/creative/officeNW.jpg`,
  `${baseUrl()}/assets/creative/officeSW.jpg`,
  `${baseUrl()}/assets/creative/pantry.jpg`,
  `${baseUrl()}/assets/creative/reception.jpg`,
  `${baseUrl()}/assets/mixed/conference.jpg`,
  `${baseUrl()}/assets/mixed/lounge.jpg`,
  `${baseUrl()}/assets/mixed/officeNW.jpg`,
  `${baseUrl()}/assets/mixed/pantry.jpg`,
  `${baseUrl()}/assets/mixed/reception.jpg`,
  `${baseUrl()}/assets/gallery/CreativeLounge.jpg`,
  `${baseUrl()}/assets/gallery/CreativeNWOffice.jpg`,
  `${baseUrl()}/assets/gallery/CreativePantry.jpg`,
  `${baseUrl()}/assets/gallery/MixedConferenceRoom.jpg`,
  `${baseUrl()}/assets/gallery/MixedLounge.jpg`,
  `${baseUrl()}/assets/gallery/MixedNW.jpg`,
  `${baseUrl()}/assets/gallery/MixedPantry.jpg`,
  `${baseUrl()}/assets/gallery/MixedReception.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/creative/CreativeLounge.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/creative/CreativeNWOffice.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/creative/CreativePantry.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/mixed/MixedConferenceRoom.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/mixed/MixedLounge.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/mixed/MixedNW.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/mixed/MixedPantry.jpg`,
  `${baseUrl()}/assets/gallery/thumbnails/mixed/MixedReception.jpg`,
];

export default srcArr;
