import { Suspense } from "react";
import styled from "styled-components";
import useCompass from "../../hooks/useCompass";

import CameraControls from "./components/CameraControls";
import Scene from "./components/Scene";
import Sphere from "./components/Sphere";
import Floorplan from "@components/Floorplan";
import { useStore } from "@state/store";

function SphericalTour() {
  const { CompassWorker, Compass } = useCompass();
  const textureIndex = useStore((s) => s.textureIndex);
  return (
    <Suspense fallback={null}>
      <Scene>
        <CameraControls />
        <Sphere />
        <CompassWorker />
      </Scene>
      <PlanWrapper>
        <Floorplan />
        <Compass>
          <svg
            version="1.1"
            id="view"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 200 200"
            enableBackground="new 0 0 200 200"
            xmlSpace="preserve"
          >
            <g>
              <circle
                fill="#ffffff"
                cx="100"
                cy="100"
                r="7.4"
                stroke="black"
                strokeWidth={1.5}
              />
              <path
                d="M100,92.9c3.9,0,7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1s-7.1-3.2-7.1-7.1S96.1,92.9,100,92.9 M100,92.4c-4.2,0-7.6,3.4-7.6,7.6
		s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S104.2,92.4,100,92.4L100,92.4z"
              />
            </g>
            <g
              style={{
                transform: textureIndex === 19 ? "rotate(180deg)" : "",
                transformOrigin: "center",
              }}
            >
              <path
                fill="#FFFFFF3C"
                d="M32.1,31.4C50,13.6,74.7,3.5,100,3.5c25.3,0,50.1,10.2,68,28l-68,67.8L32.1,31.4z"
              />
            </g>
          </svg>
        </Compass>
      </PlanWrapper>
    </Suspense>
  );
}

export default SphericalTour;

const PlanWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: clamp(200px, 300px, 400px);

  #view {
    pointer-events: none;
  }
`;
