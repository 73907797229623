import { useEffect, useState } from "react"
import styled from "styled-components"
import { a, useSpring, config } from "react-spring"

import { useStore } from "@state/store"

import Slider from "./Slider"

function Display() {
   const [open, setOpen] = useState(false)
   const galleryOpen = useStore(s => s.galleryOpen)
   const setGalleryIndex = useStore(s => s.setGalleryIndex)

   const creativePaths = [
      {
         path: `/assets/gallery/thumbnails/creative/CreativeLounge.jpg`,
         index: 0
      },
      {
         path: `/assets/gallery/thumbnails/creative/CreativeNWOffice.jpg`,
         index: 1
      },
      {
         path: `/assets/gallery/thumbnails/creative/CreativePantry.jpg`,
         index: 2
      }
   ]

   const mixedPaths = [
      {
         path: `/assets/gallery/thumbnails/mixed/MixedLounge.jpg`,
         index: 3
      },
      {
         path: `/assets/gallery/thumbnails/mixed/MixedNW.jpg`,
         index: 4
      },
      {
         path: `/assets/gallery/thumbnails/mixed/MixedPantry.jpg`,
         index: 5
      },
      {
         path: `/assets/gallery/thumbnails/mixed/MixedReception.jpg`,
         index: 6
      }
   ]

   const galleryPaths = [
      `/assets/gallery/CreativeLounge.jpg`,
      `/assets/gallery/CreativeNWOffice.jpg`,
      `/assets/gallery/CreativePantry.jpg`,
      `/assets/gallery/MixedLounge.jpg`,
      `/assets/gallery/MixedNW.jpg`,
      `/assets/gallery/MixedPantry.jpg`,
      `/assets/gallery/MixedReception.jpg`
   ]

   const [wrapperSpring, wrapperAPI] = useSpring(() => ({
      clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
   }))

   const [imgSpring, imgAPI] = useSpring(() => ({
      clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
      objectPosition: "50% 50%"
   }))

   useEffect(() => {
      wrapperAPI.start({
         from: {
            clipPath: !galleryOpen
               ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
               : "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
         },
         to: {
            clipPath: !galleryOpen
               ? "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
               : "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
         }
      })

      imgAPI.start({
         from: {
            clipPath: !galleryOpen
               ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
               : "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
            objectPosition: !galleryOpen ? "50% 50%" : "0% 50%"
         },
         to: {
            clipPath: !galleryOpen
               ? "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
               : "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            objectPosition: !galleryOpen ? "0% 50%" : "50% 50%"
         },
         config: config.stiff,
         delay: 200
      })
   }, [galleryOpen])

   return (
      <Wrapper style={wrapperSpring}>
         <Section>
            <h3>CREATIVE</h3>
            <Images>
               {creativePaths.map((obj, i) => {
                  return (
                     <a.img
                        style={imgSpring}
                        src={obj.path}
                        alt=""
                        key={`creativeThumbnail${i}`}
                        onClick={() => {
                           setGalleryIndex(obj.index)
                           setOpen(true)
                        }}
                     />
                  )
               })}
            </Images>
         </Section>
         <Section>
            <h3>MIXED</h3>
            <Images>
               {mixedPaths.map((obj, i) => {
                  return (
                     <a.img
                        style={imgSpring}
                        src={obj.path}
                        alt=""
                        key={`creativeThumbnail${i}`}
                        onClick={() => {
                           setGalleryIndex(obj.index)
                           setOpen(true)
                        }}
                     />
                  )
               })}
            </Images>
         </Section>
         <Slider images={galleryPaths} open={open} callback={setOpen}/>
      </Wrapper>
   )
}

export default Display

const Wrapper = styled(a.div)`
   position: absolute;
   z-index: 1;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;
   background-color: white;
   display: flex;
   flex-direction: column;
   gap: 3rem;
   align-items: center;
   justify-content: center;
`

const Section = styled.div`
   position: relative;
   width: 60%;
   background-color: white;
   color: black;
   font-family: title;
   border: 0.2rem solid black;

   & img {
      width: 25%;
      max-width: 280px;
      aspect-ratio: 1/1;
      object-fit: cover;
      cursor: pointer;
   }

   & h3 {
      position: relative;
      z-index: 2;
      width: 9rem;
      height: 3rem;
      border: 0.2rem solid black;
      display: grid;
      place-content: center;
      background-color: white;
      transform: translate(-50%, -50%);
   }

   @media (max-width: 2000px) {
      & img {
      max-width: 200px;
   }
   }
`

const Images = styled.div`
   width: 100%;
   height: 100%;
   padding: 2rem;
   display: flex;
   justify-content: center;
   gap: 2rem;
   flex-wrap: wrap;
   align-items: center;
   transform: translateY(-3rem);
`
