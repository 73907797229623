import { useRef } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"

import CloseIcon from "./CloseIcon"
import ArrowIcon from "./ArrowIcon"

import { useStore } from "@state/store"

function Slider({ images, open, callback }: { images:string[], open: boolean, callback: any }) {
   const galleryIndex = useStore(s => s.galleryIndex)
   const setGalleryIndex = useStore(s => s.setGalleryIndex)
   const path = useRef<string|null>(null)
   const transition = useTransition(galleryIndex !== null && open, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 }
   })

   path.current = images[galleryIndex]
   return transition(
      (anm, loaded) =>
         loaded && (
            <Wrapper style={anm}>

               <Button
                  style={{
                     top: "1rem",
                     right: "1rem"
                  }}
                  onClick={() => {
                     callback(false)
                     setTimeout(() => {
                        path.current = null
                     }, 500)
                  }}
               >
                  <CloseIcon color="black" />
               </Button>

               <Button
                  style={{
                     top: "50%",
                     right: "1rem",
                     transform: "translateY(-50%)"
                  }}
                  onClick={() => {
                     const next = galleryIndex + 1
                     next > images.length - 1 ?
                     setGalleryIndex(0) :
                     setGalleryIndex(next)
                  }}
               >
                  <ArrowIcon color="black" />
               </Button>

               <Button
                  style={{
                     top: "50%",
                     left: "1rem",
                     transform: "translateY(-50%) rotate(180deg)"
                  }}
                  onClick={() => {
                     const prev = galleryIndex - 1
                     prev < 0 ?
                     setGalleryIndex(images.length - 1) :
                     setGalleryIndex(prev)
                  }}
               >
                  <ArrowIcon color="black" />
               </Button>
               <img src={path.current} alt="" />
            </Wrapper>
         )
   )
}

export default Slider

const Wrapper = styled(a.div)`
   position: absolute;
   z-index: 2;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;

   & img {
      width: 100%;
      height: 100%;
   }
`

const Button = styled.button`
   position: absolute;
   z-index: 4;
   width: 3rem;
   height: 3rem;
   background-color: white;
   border: 0.2rem solid black;
   border-radius: 100%;
   padding: 0.5rem;
   cursor: pointer;
`
