import styled from "styled-components";
import { useEffect } from "react";
import { a, useTransition } from "react-spring";

import { useStore } from "@state/store";

type TSectionProps = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  pointColor: string;
  pointerBorderColor: string;
  pointerBorderWidth: number;
};

function CreativeSelector({
  backgroundColor,
  borderColor,
  borderWidth,
  pointColor,
  pointerBorderColor,
  pointerBorderWidth,
}: TSectionProps) {
  const tourStyle = useStore((s) => s.tourStyle);
  const textureIndex = useStore((s) => s.textureIndex);
  const setTextureIndex = useStore((s) => s.setTextureIndex);

  function handleIndexClick(n) {
    setTextureIndex(n);
  }

  function getClass(n) {
    return n === textureIndex ? "selected" : "";
  }

  return (
    tourStyle === "creative" && (
      <Wrapper>
        <g
          className={getClass(2)}
          id="officeNWCreative"
          display="inline"
          onClick={() => handleIndexClick(2)}>
          <polygon
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            points="5.66,55.1 47.83,55.1 47.83,92 8.91,92 
			8.91,90.57 5.66,90.57 		"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="26.12"
            cy="76.77"
            r="4.48"
          />
        </g>

        <g
          className={getClass(3)}
          id="officeSW"
          display="inline"
          onClick={() => handleIndexClick(3)}>
          <rect
            x="-5.98"
            y="107.05"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 142.1191 97.9801)"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="56.09"
            height="26.01"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="28.96"
            cy="131.47"
            r="4.48"
          />
        </g>

        <g
          className={getClass(4)}
          id="pantryCreative"
          display="inline"
          onClick={() => handleIndexClick(4)}>
          <polygon
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            points="88.08,92 88.08,74.4 78.52,74.4 
			78.52,55.3 47.83,55.3 47.83,92 		"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="75.79"
            cy="81.75"
            r="4.48"
          />
        </g>

        <g
          className={getClass(1)}
          id="loungeCreative"
          display="inline"
          onClick={() => handleIndexClick(1)}>
          <rect
            x="160.86"
            y="74.21"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="32.33"
            height="54.86"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="169.57"
            cy="101.45"
            r="4.48"
          />
        </g>

        <g
          className={getClass(0)}
          id="receptionCreative"
          display="inline"
          onClick={() => handleIndexClick(0)}>
          <rect
            x="35.07"
            y="111.3"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="57.46"
            height="36.8"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="70.98"
            cy="120.15"
            r="4.48"
          />
        </g>
      </Wrapper>
    )
  );
}

export default CreativeSelector;

const Wrapper = styled.g`
  cursor: pointer;
  & g {
    opacity: 0.3;
    transition: opacity 0.8s ease-in-out;
  }

  .selected {
    opacity: 1;
  }
`;
