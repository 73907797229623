//@ts-ignore
import { create } from "zustand";

interface InitialState {
  sample: number;
  setSample: (v: number) => void;
  textureIndex: number;
  setTextureIndex: (v: number) => void;
  tourStyle: "creative" | "mixed" | "catA" | "15th";
  setTourStyle: (v: "creative" | "mixed") => void;
  galleryOpen: boolean;
  setGalleryOpen: (v: boolean) => void;
  loading: boolean;
  setLoading: (v: boolean) => void;
  galleryIndex: number | null;
  setGalleryIndex: (v: number) => void;
}

export const useStore = create<InitialState>()((set) => ({
  sample: 0,
  setSample: (v) => set({ sample: v }),
  textureIndex: 0,
  setTextureIndex: (v) => set({ textureIndex: v }),
  tourStyle: "creative",
  setTourStyle: (v) => set({ tourStyle: v }),
  galleryOpen: false,
  setGalleryOpen: (v) => set({ galleryOpen: v }),
  loading: true,
  setLoading: (v) => set({ loading: v }),
  galleryIndex: null,
  setGalleryIndex: (v) => set({ galleryIndex: v }),
}));
