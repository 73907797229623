function CloseIcon({ color }: {color: string}) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g>
        <line
          stroke={color}
          strokeWidth={3}
          strokeLinecap="square"
          x1="4"
          y1="4"
          x2="20"
          y2="20"
        />
        <line
          stroke={color}
          strokeWidth={3}
          strokeLinecap="square"
          x1="20"
          y1="4"
          x2="4"
          y2="20"
        />
      </g>
    </svg>
  );
}

export default CloseIcon;
