export async function cacheImages(imgArr, onLoaded) {
  const promises = imgArr.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.addEventListener("load", () => resolve(img))
      img.addEventListener("error", () => reject())
      img.src = src
    })
  })
  await Promise.all(promises)
  onLoaded()
}
