import { useState } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring, config } from "react-spring"

function Loading({ loading }: { loading: boolean }) {
   const [show, setShow] = useState(true)
   const transition = useTransition(show, {
      from: {
         opacity: 1,
         pointerEvents: "all" as React.CSSProperties["pointerEvents"]
      },
      enter: {
         opacity: 1,
         pointerEvents: "all" as React.CSSProperties["pointerEvents"]
      },
      leave: {
         opacity: 0,
         pointerEvents: "none" as React.CSSProperties["pointerEvents"]
      },
      config: config.molasses
   })

   const buttonSpring = useSpring({
      from: {
         opacity: 0,
         pointerEvents: "none" as React.CSSProperties["pointerEvents"]
      },
      to: {
         opacity: loading ? 0 : 1,
         pointerEvents: loading
            ? "none"
            : ("all" as React.CSSProperties["pointerEvents"])
      },
      config: config.wobbly,
      delay: 500
   })

   const spinnerSpring = useSpring({
      from: {
         opacity: 1
      },
      to: {
         opacity: loading ? 1 : 0
      }
   })

   return transition(
      (anm, loaded) =>
         loaded && (
            <Wrapper style={anm}>
               <LogoWarpper>
                  <svg
                     width="100%"
                     viewBox="0 0 808 386"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <g>
                        <polygon
                           points="311,288.1 327.9,288.1 327.9,103.9 311,120.3 	"
                           fill="black"
                        />
                        <path
                           d="M256.5,228.8c14.9-14.5,23.2-33.9,23.2-54.8c0-30-17.4-55.9-42.6-68.4c-6.4-35.6-37.6-62.6-75-62.6
		c-42,0-76.2,34.2-76.2,76.2l20.3-19.8c8.1-23,30.1-39.5,55.9-39.5c25.7,0,47.5,16.4,55.8,39.3c-4.7-0.9-9.5-1.4-14.4-1.4
		c-42,0-76.2,34.2-76.2,76.2l20.3-19.7l0-0.1c8.1-23,30.1-39.5,55.9-39.5c6.2,0,12.2,1,17.9,2.7c0,0.6,0.1,1.2,0.1,1.8
		c0,16.2-6.4,31.3-18,42.6L73.5,288.2h97.7L114.8,343h156.4l17.4-16.9H156.4L256.5,228.8z M115.1,271.3l100-97.3
		c6.7-6.5,12-13.9,15.9-22c3.9-8.1,6.2-16.9,7-26.1c15,10.8,24.8,28.3,24.8,48.1c0,16.2-6.4,31.3-18,42.6l-56.2,54.6H115.1z"
                           fill="black"
                        />
                        <polygon
                           points="36.5,288.1 53.4,288.1 53.4,103.9 36.5,120.4 	"
                           fill="black"
                        />
                        <path
                           d="M420.8,135.1h21.4l3.5,9.1h8.1L435.5,100l-0.1-0.3h-7.9l-18.3,44.3h8.1L420.8,135.1z M423.1,128.6l8.3-19.8l8.3,19.8H423.1
		z"
                           fill="black"
                        />
                        <polygon
                           points="480.5,144.1 497.6,99.8 489.6,99.8 476.2,135.3 462.8,100.1 462.7,99.8 454.8,99.8 471.9,144.1 	"
                           fill="black"
                        />
                        <polygon
                           points="539.7,137.2 515.3,137.2 515.3,124.8 535.7,124.8 535.7,117.9 515.3,117.9 515.3,106.7 539.1,106.7 539.1,99.8 
		507.7,99.8 507.7,144.1 539.7,144.1 	"
                           fill="black"
                        />
                        <polygon
                           points="560,112.1 585.6,144 585.8,144.1 593.2,144.1 593.2,99.8 585.6,99.8 585.6,132.1 559.9,99.9 559.7,99.8 
		552.4,99.8 552.4,144.1 560,144.1 	"
                           fill="black"
                        />
                        <path
                           d="M645.6,128V99.8h-7.5v27.9c0,6.2-4.3,10-11.2,10c-6.9,0-11.2-3.8-11.2-10V99.8h-7.5V128c0,10.2,7.3,16.8,18.7,16.8
		C638.2,144.8,645.6,138.2,645.6,128z"
                           fill="black"
                        />
                        <polygon
                           points="692.6,137.2 668.2,137.2 668.2,124.8 688.6,124.8 688.6,117.9 668.2,117.9 668.2,106.7 691.9,106.7 691.9,99.8 
		660.6,99.8 660.6,144.1 692.6,144.1 	"
                           fill="black"
                        />
                        <path
                           d="M434.8,171.1c-13.2,0-22.9,9.6-22.9,22.9c0,13.2,9.6,22.7,22.9,22.7c13.3,0,22.9-9.6,22.9-22.7
		C457.7,180.8,448.1,171.1,434.8,171.1z M434.8,209.5c-9,0-15-6.3-15-15.6c0-9.2,6.2-15.6,15-15.6c9,0,15,6.3,15,15.6
		S443.8,209.5,434.8,209.5z"
                           fill="black"
                        />
                        <polygon
                           points="501.5,178.7 501.5,171.8 470.4,171.8 470.4,216.1 478,216.1 478,197 496.4,197 496.4,190 478,190 478,178.7 	"
                           fill="black"
                        />
                        <polygon
                           points="524.9,178.9 539.2,178.9 539.2,216.1 546.8,216.1 546.8,178.9 561.1,178.9 561.1,171.8 524.9,171.8 	"
                           fill="black"
                        />
                        <polygon
                           points="602.1,190 579.4,190 579.4,171.8 571.8,171.8 571.8,216.1 579.4,216.1 579.4,197 602.1,197 602.1,216.1 
		609.7,216.1 609.7,171.8 602.1,171.8 	"
                           fill="black"
                        />
                        <polygon
                           points="656.5,178.7 656.5,171.8 625.2,171.8 625.2,216.1 657.2,216.1 657.2,209.2 632.8,209.2 632.8,196.8 653.2,196.8 
		653.2,189.9 632.8,189.9 632.8,178.7 	"
                           fill="black"
                        />
                        <path
                           d="M435.4,243.8h-7.9l-18.3,44.3h8.1l3.5-9.1h21.4l3.5,9.1h8.1l-18.2-44.1L435.4,243.8z M439.8,272.6h-16.7l8.3-19.8
		L439.8,272.6z"
                           fill="black"
                        />
                        <polygon
                           points="487.1,274.2 472.4,243.8 463.7,243.8 463.7,288.1 471.3,288.1 471.3,258.4 484.4,285.1 489.8,285.1 502.9,258.4 
		502.9,288.1 510.5,288.1 510.5,243.8 501.9,243.8 	"
                           fill="black"
                        />
                        <polygon
                           points="533.6,268.8 554,268.8 554,261.9 533.6,261.9 533.6,250.8 557.4,250.8 557.4,243.8 526,243.8 526,288.1 558,288.1 
		558,281.2 533.6,281.2 	"
                           fill="black"
                        />
                        <path
                           d="M605,257.4c0-8.3-6-13.6-15.2-13.6h-19.1v44.3h7.6v-17.1h10.2l9.8,17.1h8.8l-10.7-18.2C601.8,267.8,605,263.2,605,257.4z
		 M578.2,250.7h11.2c4.9,0,7.7,2.5,7.7,6.7c0,4.2-2.9,6.8-7.7,6.8h-11.2V250.7z"
                           fill="black"
                        />
                        <rect
                           x="618.4"
                           y="243.8"
                           width="7.6"
                           height="44.3"
                           fill="black"
                        />
                        <path
                           d="M675.3,274c-3.3,4.9-8.1,7.5-13.7,7.5c-8.7,0-15-6.6-15-15.6s6.3-15.6,14.9-15.6c5.6,0,10.2,2.6,13.5,7.7l0.2,0.4l5.9-4.1
		l-0.2-0.3c-4.3-6.9-11.4-10.8-19.5-10.8c-12.8,0-22.8,10-22.8,22.8c0,12.8,10.1,22.8,22.9,22.8c8.2,0,15.3-3.9,19.5-10.8l0.2-0.3
		l-5.7-4L675.3,274z"
                           fill="black"
                        />
                        <path
                           d="M713.3,243.8h-7.9l-18.3,44.3h8.1l3.5-9.1h21.4l3.5,9.1h8.1l-18.2-44.1L713.3,243.8z M717.7,272.6H701l8.3-19.8
		L717.7,272.6z"
                           fill="black"
                        />
                        <path
                           d="M756.5,262.3l-0.9-0.3c-5.6-1.9-8.6-2.9-8.6-6.3c0-3.6,3.8-5.5,7.6-5.5c5.9,0,9,4.4,10,6.3l0.2,0.4l5.9-4.1l-0.2-0.3
		c-1.4-2.8-5.9-9.2-16-9.2c-7.5,0-15.2,4.3-15.2,12.5c-0.1,8,6.8,10.8,15,13.5c6.6,2.1,9.6,3.6,9.6,6.9c0,3.4-3.5,5.8-8.5,5.8
		c-5.2,0-9.4-2.5-11.6-7.1l-0.2-0.4l-5.7,4l0.2,0.3c2.8,6.2,9.5,10.1,17.5,10.1c7.9,0,16-4.4,16-12.8
		C771.5,267.3,764.5,265,756.5,262.3z"
                           fill="black"
                        />
                     </g>
                  </svg>
               </LogoWarpper>
               <a.img
                  style={spinnerSpring}
                  src="/assets/spinner.png"
                  alt=""
                  className="spinner"
               />
               <Enter
                  style={buttonSpring}
                  onClick={() => {
                     setShow(false)
                  }}
               >
                  ENTER
               </Enter>
            </Wrapper>
         )
   )
}

export default Loading

const Wrapper = styled(a.div)`
   position: absolute;
   top: 0;
   left: 0;
   z-index: 15;
   width: 100%;
   height: 100%;
   background-color: white;

   .spinner {
      position: absolute;
      bottom: 20.5%;
      left: 50%;
      width: 6rem;
      height: 6rem;
      -webkit-animation: rotate-center 1.5s ease-in-out infinite both;
      animation: rotate-center 1.5s ease-in-out infinite both;
   }

   @-webkit-keyframes rotate-center {
      0% {
         -webkit-transform: translate(-50%, 0%) rotate(0deg);
         transform: translate(-50%, 0%) rotate(0deg);
      }
      100% {
         -webkit-transform: translate(-50%, 0%) rotate(360deg);
         transform: translate(-50%, 0%) rotate(360deg);
      }
   }

   @keyframes rotate-center {
      0% {
         -webkit-transform: translate(-50%, 0%) rotate(0deg);
         transform: translate(-50%, 0%) rotate(0deg);
      }
      100% {
         -webkit-transform: translate(-50%, 0%) rotate(360deg);
         transform: translate(-50%, 0%) rotate(360deg);
      }
   }
`

const LogoWarpper = styled.div`
   position: absolute;
   width: 40%;
   height: 30%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`

const Enter = styled(a.button)`
   position: absolute;
   padding: 1rem 2rem 1.1rem 2rem;
   bottom: 20%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-family: title;
   background-color: black;
   color: white;
   font-size: 1.2rem;
   border: none;
   outline: none;
   cursor: pointer;
`
