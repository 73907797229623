import React from "react"
import styled from "styled-components"
import { a, useSpring, useTransition } from"react-spring"

import { useStore } from "@state/store"
import Display from "./components/Display"

function Gallery() {
   const galleryOpen = useStore(s => s.galleryOpen)
   const setGalleryOpen = useStore(s => s.setGalleryOpen)
   const setGalleryIndex = useStore(s => s.setGalleryIndex)

   const buttonCopySpring = useSpring({
    from:{opacity: galleryOpen ? 0 : 1},
    to:{opacity: galleryOpen ? 1 : 0}
   })

   return (
      <>
         <Button
            onClick={() => {
               setGalleryOpen(!galleryOpen)
               setTimeout(() => {
                  setGalleryIndex(null)
               }, 800)
            }}
         >
         <span>GALLERY</span>
          <ButtonCopy style={buttonCopySpring}>
            360 TOUR
          </ButtonCopy>
         </Button>
         <Display/>
      </>
   )
}

export default Gallery

const Button = styled(a.button)`
   position: absolute;
   z-index: 2;
   bottom: 1rem;
   left: 1rem;
   width: 9rem;
   height: 3rem;
   background-color: white;
   color: black;
   border: 0.2rem solid black;
   font-family: title;
   font-size: 1.2rem;
   cursor: pointer;
   display: grid;
   place-content: center;

   & span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: white;
    display: grid;
    place-content: center;
   }
`

const ButtonCopy = styled(a.span)`
`
