import { useEffect, useState } from "react"
import styled from "styled-components"
import { a, useSpring } from "react-spring"

import Icon from "./Icon"

import { useStore } from "@state/store"

function ImageDisplay() {
   const textureIndex = useStore(s => s.textureIndex)
   const galleryOpen = useStore(s => s.galleryOpen)
   const [open, setOpen] = useState(false)
   const indexes = [1, 2, 4, 5, 6, 7, 8, 9]
   const paths = {
      1: "/assets/gallery/CreativeLounge.jpg",
      2: "/assets/gallery/CreativeNWOffice.jpg",
      4: "/assets/gallery/CreativePantry.jpg",
      5: "/assets/gallery/MixedReception.jpg",
      6: "/assets/gallery/MixedLounge.jpg",
      7: "/assets/gallery/MixedNW.jpg",
      8: "/assets/gallery/MixedConferenceRoom.jpg",
      9: "/assets/gallery/MixedPantry.jpg"
   }

   const [buttonSpring, buttonAPI] = useSpring(() => ({
      opacity: 0,
      pointerEvents: `none` as React.CSSProperties["pointerEvents"]
   }))

   const [imageSpring, imageAPI] = useSpring(() => ({
      clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
   }))

   function handleClick() {
      imageAPI.start({
         from: {
            clipPath: open
               ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
               : "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
         },
         to: {
            clipPath: open
               ? "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
               : "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
         }
      })
      setOpen(!open)
   }

   useEffect(() => {
      buttonAPI.start({
         from: {
            opacity: indexes.includes(textureIndex) && !galleryOpen ? 1 : 0,
            pointerEvents:
               indexes.includes(textureIndex) && !galleryOpen
                  ? (`all` as React.CSSProperties["pointerEvents"])
                  : (`none` as React.CSSProperties["pointerEvents"])
         },
         to: {
            opacity: indexes.includes(textureIndex) && !galleryOpen ? 1 : 0,
            pointerEvents:
               indexes.includes(textureIndex) && !galleryOpen
                  ? (`all` as React.CSSProperties["pointerEvents"])
                  : (`none` as React.CSSProperties["pointerEvents"])
         }
      })
   }, [textureIndex, galleryOpen])

   return (
      <>
         <Button
            style={buttonSpring}
            onClick={() => {
               handleClick()
            }}
         >
            <Icon color="black" open={open} />
         </Button>
         <Image style={imageSpring} src={paths[textureIndex]} alt="" />
      </>
   )
}

export default ImageDisplay

const Button = styled(a.button)`
   position: absolute;
   z-index: 4;
   top: 1rem;
   right: 1rem;
   width: 3rem;
   height: 3rem;
   background-color: white;
   border: 0.2rem solid black;
   border-radius: 100%;
   padding: 0.5rem;
   cursor: pointer;
`

const Image = styled(a.img)`
   position: absolute;
   z-index: 3;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;
   max-height: 100%;
   max-width: 100%;
`
