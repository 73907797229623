import styled from "styled-components";
import { useEffect } from "react";
import { a, useTransition } from "react-spring";

import { useStore } from "@state/store";

type TSectionProps = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  pointColor: string;
  pointerBorderColor: string;
  pointerBorderWidth: number;
};

function MixedSelector({
  backgroundColor,
  borderColor,
  borderWidth,
  pointColor,
  pointerBorderColor,
  pointerBorderWidth,
}: TSectionProps) {
  const tourStyle = useStore((s) => s.tourStyle);
  const textureIndex = useStore((s) => s.textureIndex);
  const setTextureIndex = useStore((s) => s.setTextureIndex);

  function handleIndexClick(n) {
    setTextureIndex(n);
  }

  function getClass(n) {
    return n === textureIndex ? "selected" : "";
  }

  return (
    tourStyle === "mixed" && (
      <Wrapper>
        <g
          className={getClass(7)}
          id="officeNW"
          display="inline"
          onClick={() => handleIndexClick(7)}>
          <polygon
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            points="5.66,55.1 78.44,55.1 78.44,92 8.91,92 
  8.91,90.57 5.66,90.57 		"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="32.47"
            cy="81.25"
            r="4.48"
          />
        </g>

        <g
          className={getClass(8)}
          id="conference"
          display="inline"
          onClick={() => handleIndexClick(8)}>
          <rect
            x="145.19"
            y="74.19"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="48.01"
            height="17.81"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="182.18"
            cy="83.78"
            r="4.48"
          />
        </g>

        <g
          className={getClass(9)}
          id="pantry"
          display="inline"
          onClick={() => handleIndexClick(9)}>
          <polygon
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            points="150.78,129.07 150.78,111.3 
  160.86,111.3 160.86,92 193.2,92 193.2,129.07 		"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="178.44"
            cy="101.59"
            r="4.48"
          />
        </g>

        <g
          className={getClass(6)}
          onClick={() => handleIndexClick(6)}
          id="lounge"
          display="inline">
          <rect
            x="92.54"
            y="111.3"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="58.25"
            height="37.08"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="129.03"
            cy="139.84"
            r="4.48"
          />
        </g>

        <g
          className={getClass(5)}
          id="reception"
          display="inline"
          onClick={() => handleIndexClick(5)}>
          <rect
            x="35.07"
            y="111.3"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="57.46"
            height="36.8"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="70.98"
            cy="120.15"
            r="4.48"
          />
        </g>
      </Wrapper>
    )
  );
}

export default MixedSelector;

const Wrapper = styled.g`
  cursor: pointer;
  & g {
    opacity: 0.3;
    transition: opacity 0.8s ease-in-out;
  }

  .selected {
    opacity: 1;
  }
`;
