import styled from "styled-components";

import Background from "./Sections/Background";
import Background2 from "./Sections/15thBackground";
import MixedSelector from "./Sections/MixedSelector";
import CreativeSelector from "./Sections/CreativeSelector";
import CatASelector from "./Sections/CatASelector";
import Whitebox15thSelector from "./Sections/Whitebox15thSelector";
import { useStore } from "@state/store";

function Floorplan() {
  const tourStyle = useStore((s) => s.tourStyle);
  return (
    <SVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="100%"
      y="100%"
      viewBox="0 0 200 200"
    >
      {tourStyle === "15th" ? (
        <Background2
          backgroundColor="#ffffff14"
          borderColor="#000000"
          borderWidth={0.5}
        />
      ) : (
        <Background
          backgroundColor="#ffffff14"
          borderColor="#000000"
          borderWidth={0.5}
        />
      )}
      <MixedSelector
        backgroundColor="white"
        borderColor="black"
        borderWidth={0.5}
        pointColor="white"
        pointerBorderColor="black"
        pointerBorderWidth={1.5}
      />
      <CreativeSelector
        backgroundColor="white"
        borderColor="black"
        borderWidth={0.5}
        pointColor="white"
        pointerBorderColor="black"
        pointerBorderWidth={1.5}
      />
      <CatASelector
        backgroundColor="white"
        borderColor="black"
        borderWidth={0.5}
        pointColor="white"
        pointerBorderColor="black"
        pointerBorderWidth={1.5}
      />
      <Whitebox15thSelector
        backgroundColor="white"
        borderColor="black"
        borderWidth={0.5}
        pointColor="white"
        pointerBorderColor="black"
        pointerBorderWidth={1.5}
      />
    </SVG>
  );
}

export default Floorplan;

const SVG = styled.svg`
  transform: rotate(0deg);
  position: absolute;
`;
