function ArrowIcon({ color }: {color: string}) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g>
        <polyline
          stroke={color}
          fill="none"
          strokeWidth={3}
          strokeLinecap="square"
          points="8,4 16,12 8,20"
        />
      </g>
    </svg>
  );
}

export default ArrowIcon;
