import styled from "styled-components";
import { useEffect } from "react";
import { a, useTransition } from "react-spring";

import { useStore } from "@state/store";

type TSectionProps = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  pointColor: string;
  pointerBorderColor: string;
  pointerBorderWidth: number;
};

function CatASelector({
  backgroundColor,
  borderColor,
  borderWidth,
  pointColor,
  pointerBorderColor,
  pointerBorderWidth,
}: TSectionProps) {
  const tourStyle = useStore((s) => s.tourStyle);
  const textureIndex = useStore((s) => s.textureIndex);
  const setTextureIndex = useStore((s) => s.setTextureIndex);

  function handleIndexClick(n) {
    setTextureIndex(n);
  }

  function getClass(n) {
    return n === textureIndex ? "selected" : "";
  }

  return (
    tourStyle === "15th" && (
      <Wrapper>
        <g
          className={getClass(15)}
          onClick={() => handleIndexClick(15)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="67"
            y="91"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="66"
            height="19"
          />
          {/* <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="184"
            cy="80"
            r="4.48"
          /> */}
        </g>

        <g
          className={getClass(16)}
          onClick={() => handleIndexClick(16)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="66"
            y="71"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="67"
            height="19"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="82"
            cy="80"
            r="4.48"
          />
        </g>

        <g
          className={getClass(17)}
          onClick={() => handleIndexClick(17)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="133"
            y="71"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="66"
            height="19"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="184"
            cy="80"
            r="4.48"
          />
        </g>

        <g
          className={getClass(18)}
          onClick={() => handleIndexClick(18)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="1"
            y="71"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="65"
            height="19"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="15"
            cy="80"
            r="4.48"
          />
        </g>

        <g
          className={getClass(19)}
          onClick={() => handleIndexClick(19)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="66"
            y="112"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="67"
            height="17"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="82"
            cy="120"
            r="4.48"
          />
        </g>

        <g
          className={getClass(20)}
          onClick={() => handleIndexClick(20)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="133"
            y="112"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="66"
            height="17"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="184"
            cy="120"
            r="4.48"
          />
        </g>

        <g
          className={getClass(21)}
          onClick={() => handleIndexClick(21)}
          id="officeSWCatA"
          display="inline"
        >
          <rect
            x="1"
            y="112"
            fill={backgroundColor}
            stroke={borderColor}
            strokeWidth={borderWidth}
            width="65"
            height="17"
          />
          <circle
            fill={pointColor}
            stroke={pointerBorderColor}
            strokeWidth={pointerBorderWidth}
            cx="15"
            cy="120"
            r="4.48"
          />
        </g>
      </Wrapper>
    )
  );
}

export default CatASelector;

const Wrapper = styled.g`
  cursor: pointer;
  & g {
    opacity: 0.3;
    transition: opacity 0.8s ease-in-out;
  }

  .selected {
    opacity: 1;
  }
`;
