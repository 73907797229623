import { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { useSpring } from "react-spring";
import { useFrame } from "@react-three/fiber";

import Material from "./AnimatedMaterial";

import { useStore } from "@state/store";
import useHotSpots from "@data/toursData";
import { useTexture } from "@react-three/drei";

function Sphere() {
  const tourData = useHotSpots();
  const textureIndex = useStore((s) => s.textureIndex);
  const setTextureIndex = useStore((s) => s.setTextureIndex);
  const tourStyle = useStore((s) => s.tourStyle);
  const prevTourStyle = useRef("creative");
  const currentTexture = useRef(0);
  const [animating, setAnimating] = useState(false);
  const [hover, setHover] = useState(false);

  const textures = tourData.paths.map((path) =>
    useLoader(THREE.TextureLoader, path)
  );

  //  const textures = useTexture(tourData.paths)
  textures.forEach((texture) => {
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.x = 1;
    texture.flipX = true;
  });

  const hotspots = tourData.hotspots[textureIndex];

  const [blend, blendApi] = useSpring(() => ({
    value: 0,
  }));

  useEffect(() => {
    if (tourStyle === "creative") {
      if (prevTourStyle.current === "mixed") {
        setTextureIndex(textureIndex - 5);
      } else if (prevTourStyle.current === "catA") {
        setTextureIndex(textureIndex - 10);
      } else if (prevTourStyle.current === "15th") {
        setTextureIndex(textureIndex - 17);
      }
    } else if (tourStyle === "mixed") {
      if (prevTourStyle.current === "creative") {
        setTextureIndex(textureIndex + 5);
      } else if (prevTourStyle.current === "catA") {
        setTextureIndex(textureIndex - 5);
      } else if (prevTourStyle.current === "15th") {
        setTextureIndex(textureIndex - 12);
      }
    } else if (tourStyle === "catA") {
      if (prevTourStyle.current === "creative") {
        setTextureIndex(textureIndex + 10);
      } else if (prevTourStyle.current === "mixed") {
        setTextureIndex(textureIndex + 5);
      } else if (prevTourStyle.current === "15th") {
        setTextureIndex(textureIndex - 7);
      }
    } else if (tourStyle === "15th") {
      if (prevTourStyle.current === "creative") {
        setTextureIndex(textureIndex + 17);
      } else if (prevTourStyle.current === "mixed") {
        setTextureIndex(textureIndex + 12);
      } else if (prevTourStyle.current === "catA") {
        setTextureIndex(textureIndex + 7);
      }
    }

    prevTourStyle.current = tourStyle;
  }, [tourStyle]);

  useFrame((state, delta) => {
    if (hotspots) {
      hotspots.forEach((ht) => {
        //@ts-ignore
        ht.reference.current.rotation.y += delta * 2;
      });
    }
  });

  useEffect(() => {
    // console.log(textureIndex)
    if (!animating) {
      blendApi.start({
        from: {
          value: 0,
        },
        to: {
          value: 1,
        },
        config: { duration: 1000 },
        onStart: () => {
          setAnimating(true);
        },
        onRest: () => {
          currentTexture.current = textureIndex;
          setAnimating(false);
        },
      });
    }
  }, [textureIndex]);

  useEffect(() => {
    // console.log(textureIndex)
    document.body.style.cursor = hover ? "pointer" : "auto";
  }, [hover]);

  return (
    <mesh visible scale={[-1, 1, 1]} position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <sphereGeometry args={[500, 60, 40]} />

      <Material
        initialTexture={textures[currentTexture.current]}
        nextTexture={textures[textureIndex]}
        blendValue={blend.value}
      />
      {hotspots?.map((ht, i) => {
        return (
          <group
            key={`hotspot${i}`}
            position={ht.position}
            onClick={() => {
              textureIndex !== ht.changeTo && setTextureIndex(ht.changeTo);
            }}
          >
            <mesh
              scale={[6, 6, 6]}
              onPointerOver={() => {
                setHover(true);
              }}
              onPointerOut={() => {
                setHover(false);
              }}
            >
              <sphereGeometry args={[1, 60, 40]} />
              <meshBasicMaterial
                depthTest={false}
                side={THREE.DoubleSide}
                color={"black"}
                transparent
                opacity={0.6}
              />
            </mesh>
            <mesh scale={[6, 6, 6]} ref={ht.reference}>
              <ringGeometry args={[1.5, 2, 40]} />
              <meshBasicMaterial
                side={THREE.DoubleSide}
                color={"black"}
                transparent
                opacity={0.6}
              />
            </mesh>
          </group>
        );
      })}
    </mesh>
  );
}

export default Sphere;
