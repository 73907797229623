import React from "react";

type TBackgroundProps = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
};

function Background({
  backgroundColor,
  borderColor,
  borderWidth,
}: TBackgroundProps) {
  return (
    <g id="background">
      <polygon
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        points="5.66,55.1 78.44,55.1 78.44,74.19 
		193.2,74.19 193.2,129.07 150.78,129.07 150.78,148.38 8.91,148.38 8.91,90.57 5.66,90.57 	"
      />
      <rect
        x="8.91"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="18.56"
        height="8.36"
      />
      <rect
        x="8.91"
        y="100.36"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="8.58"
        height="3.42"
      />
      <rect
        x="35.07"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.72"
        height="19.17"
      />
      <rect
        x="40.79"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="7.04"
        height="19.17"
      />
      <rect
        x="47.83"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.19"
        height="19.17"
      />
      <rect
        x="53.01"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="15.4"
        height="19.17"
      />
      <rect
        x="68.41"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="4.86"
        height="19.17"
      />
      <rect
        x="73.27"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.29"
        height="19.17"
      />
      <rect
        x="78.55"
        y="106.31"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.32"
        height="4.72"
      />
      <rect
        x="78.55"
        y="101.59"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.32"
        height="4.73"
      />
      <polygon
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        points="83.87,101.59 78.55,101.59 78.55,97.09 
		80.07,97.09 80.07,98.71 83.87,98.69 	"
      />
      <rect
        x="78.55"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="13.98"
        height="4.84"
      />
      <rect
        x="87.44"
        y="96.84"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.09"
        height="14.33"
      />
      <rect
        x="92.54"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="4.46"
        height="19.17"
      />
      <rect
        x="102.34"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="4.82"
        height="19.17"
      />
      <rect
        x="107.16"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="8.65"
        height="19.17"
      />
      <rect
        x="115.81"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.48"
        height="19.17"
      />
      <rect
        x="121.29"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="4.41"
        height="19.17"
      />
      <rect
        x="131.03"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="8.92"
        height="19.17"
      />
      <rect
        x="145.19"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.2"
        height="19.17"
      />
      <rect
        x="150.39"
        y="92"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="10.47"
        height="4.97"
      />
      <rect
        x="155.63"
        y="96.97"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.24"
        height="3.82"
      />

      <rect
        x="155.63"
        y="100.79"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="5.24"
        height="10.38"
      />
      <rect
        x="152.46"
        y="108.99"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="3.17"
        height="2.18"
      />
      <rect
        x="8.91"
        y="141.16"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        width="4.29"
        height="7.22"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="8.91"
        y1="92"
        x2="27.47"
        y2="100.36"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="8.91"
        y1="100.36"
        x2="27.47"
        y2="92"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="17.49"
        y1="100.36"
        x2="8.91"
        y2="103.78"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="17.49"
        y1="103.78"
        x2="9.07"
        y2="100.55"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="73.27"
        y1="92"
        x2="78.32"
        y2="111.04"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="73.27"
        y1="111.17"
        x2="78.55"
        y2="92"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="87.44"
        y1="96.84"
        x2="92.54"
        y2="111.17"
      />
      <path
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        d="M92.54,97.09c0,0.09-5.09,14.08-5.09,14.08"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="92.54"
        y1="92"
        x2="96.99"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="96.99"
        y1="92"
        x2="92.77"
        y2="111.04"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="102.34"
        y1="92"
        x2="107.16"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="107.16"
        y1="92"
        x2="102.34"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="121.29"
        y1="92"
        x2="125.7"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="125.7"
        y1="92"
        x2="121.29"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="131.03"
        y1="92"
        x2="139.96"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="139.96"
        y1="92"
        x2="131.03"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="145.19"
        y1="92"
        x2="150.16"
        y2="111.17"
      />
      <polyline
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        points="145.16,111.3 150.31,92.31 
		160.86,96.97 	"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="150.39"
        y1="96.97"
        x2="160.86"
        y2="92"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="155.63"
        y1="100.79"
        x2="160.86"
        y2="111.17"
      />
      <line
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        x1="160.86"
        y1="100.79"
        x2="155.63"
        y2="111.17"
      />
    </g>
  );
}

export default Background;
