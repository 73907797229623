import { a, useSpring } from "react-spring";

type TIconProps = {
  color: string;
  open: boolean;
};

function CameraIcon({ color, open }: TIconProps) {
  const cameraSpring = useSpring({
    opacity: !open ? 1 : 0,
    delay: open ? 0 : 500
  });
  
  const xOneSpring = useSpring({
    opacity: open ? 1 : 0,
    x: open ? "20" : "4",
    delay: open ? 500 : 0
  });
  const xTwoSpring = useSpring({
    opacity: open ? 1 : 0,
    x: open ? "4" : "20",
    y: open ? "20" : "4",
    delay: open ? 500 : 0
  });
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      {/* Camera */}
      <a.g style={cameraSpring}>
        <path
          fill={color}
          d="M19,20H5c-1.654,0-3-1.346-3-3V9c0-1.654,1.346-3,3-3h1.586l1-1C8.165,4.421,9.182,4,10,4h4c0.818,0,1.835,0.421,2.414,1   l1,1H19c1.654,0,3,1.346,3,3v8C22,18.654,20.654,20,19,20z M5,8C4.448,8,4,8.448,4,9v8c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1   V9c0-0.552-0.448-1-1-1h-2c-0.266,0-0.52-0.105-0.707-0.293L15,6.414C14.799,6.213,14.285,6,14,6h-4C9.715,6,9.201,6.213,9,6.414   L7.707,7.707C7.52,7.895,7.266,8,7,8H5z"
        />
        <path
          fill={color}
          d="M12,10c1.379,0,2.5,1.121,2.5,2.5S13.379,15,12,15s-2.5-1.121-2.5-2.5S10.621,10,12,10 M12,9c-1.934,0-3.5,1.566-3.5,3.5   c0,1.932,1.566,3.5,3.5,3.5s3.5-1.568,3.5-3.5C15.5,10.566,13.934,9,12,9z"
        />
        <path
          fill={color}
          d="M18,8.699c-0.719,0-1.3,0.582-1.3,1.301s0.581,1.299,1.3,1.299s1.3-0.58,1.3-1.299S18.719,8.699,18,8.699z"
        />
      </a.g>
      {/* X */}
      <g>
        <a.line
          style={{
            opacity: xOneSpring.opacity
          }}
          stroke={color}
          strokeWidth={3}
          strokeLinecap="square"
          x1="4"
          y1="4"
          x2={xOneSpring.x}
          y2={xOneSpring.x}
        />
        <a.line
          style={{
            opacity: xTwoSpring.opacity
          }}
          stroke={color}
          strokeWidth={3}
          strokeLinecap="square"
          x1="20"
          y1="4"
          x2={xTwoSpring.x}
          y2={xTwoSpring.y}
        />
      </g>
    </svg>
  );
}

export default CameraIcon;
