import React from "react";

type TBackgroundProps = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
};

function Background({
  backgroundColor,
  borderColor,
  borderWidth,
}: TBackgroundProps) {
  return (
    <g id="background">
      <path
        d="M0.978516 71.1829H75.742H199.14V129.215H153.527H0.978516V87.8173V71.1829Z"
        fill="white"
        fillOpacity="0.0784314"
        stroke="black"
        strokeWidth="0.537634"
      />
      <path
        d="M39.7849 89.3547H85.2215H160.215V111.935H132.494H39.7849V95.8273V89.3547Z"
        fill="white"
        fillOpacity="0.0784314"
        stroke="black"
        strokeWidth="0.537634"
      />
      <path
        d="M41.9354 91.5054H59.3798H88.172V109.785H77.5292H41.9354V96.745V91.5054Z"
        fill="white"
        fillOpacity="0.0784314"
        stroke="black"
        strokeWidth="0.412826"
      />
      <path
        d="M95.699 91.5054H103.813H117.204V109.785H112.254H95.699V96.745V91.5054Z"
        fill="white"
        fillOpacity="0.0784314"
        stroke="black"
        strokeWidth="0.412826"
      />
      <path
        d="M124.731 91.5054H137.307H158.065V109.785H150.392H124.731V96.745V91.5054Z"
        fill="white"
        fillOpacity="0.0784314"
        stroke="black"
        strokeWidth="0.412826"
      />
    </g>
  );
}

export default Background;
