import { useEffect, useRef } from "react";
import { animated } from "@react-spring/three";
import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";
import * as THREE from "three";

//@ts-ignore
import Fragment from "./shaders/fragmentSpring.glsl";
//@ts-ignore
import Vertex from "./shaders/vertexSpring.glsl";

const TransitionMaterial = shaderMaterial(
  {
    uCurrentTexture: { value: new THREE.Texture() },
    uNextTexture: { value: new THREE.Texture() },
    uBlend: { value: 0 },
  },
  Vertex,
  Fragment,
  () => null
);

extend({ TransitionMaterial });

//@ts-ignore
const TextureTransition = animated("transitionMaterial");

// const TextureTransition = animated(({currentTexture, nextTexture, number}) => {
//   return(
//     //@ts-ignore
//     <transitionMaterial uCurrentTexture={currentTexture} uNextTexture={nextTexture} uBlend={number}/>
//   )
// })

function AnimatedMaterial({ initialTexture, nextTexture, blendValue }) {
  const materialRef = useRef<THREE.ShaderMaterial>();
  return (
    //@ts-ignore
    <TextureTransition
      ref={materialRef}
      uCurrentTexture={initialTexture}
      uNextTexture={nextTexture}
      uBlend={blendValue}
      side={THREE.BackSide}
      attach="material"
    />
  );
}

export default AnimatedMaterial;
