import styled from "styled-components";

import { useStore } from "@state/store";

function Controls() {
  const setTourStyle = useStore((s) => s.setTourStyle);
  const tourStyle = useStore((s) => s.tourStyle);

  function handleStyleClick(n) {
    setTourStyle(n);
  }
  return (
    <Wrapper>
      <Button
        className={tourStyle === "creative" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("creative");
        }}
      >
        CREATIVE
      </Button>
      <Button
        className={tourStyle === "mixed" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("mixed");
        }}
      >
        MIXED
      </Button>
      <Button
        className={tourStyle === "catA" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("catA");
        }}
      >
        WHITEBOX
      </Button>
      <Button
        className={tourStyle === "15th" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("15th");
        }}
      >
        typical tower floor
      </Button>
    </Wrapper>
  );
}

export default Controls;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .selected {
    opacity: 1;
  }
`;

const Button = styled.button`
  min-width: 12rem;
  height: 4rem;
  background-color: white;
  color: black;
  border: 0.2rem solid black;
  font-family: title;
  font-size: 1.2rem;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.8s ease-in-out;
  padding: 0rem 1rem;
  text-transform: uppercase;
`;
