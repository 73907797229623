import { useRef } from "react";

function useHotSpots() {
  const data = {
    paths: [
      "/assets/creative/reception.jpg",
      "/assets/creative/lounge.jpg",
      "/assets/creative/officeNW.jpg",
      "/assets/creative/officeSW.jpg",
      "/assets/creative/pantry.jpg",

      "/assets/mixed/reception.jpg",
      "/assets/mixed/lounge.jpg",
      "/assets/mixed/officeNW.jpg",
      "/assets/mixed/conference.jpg",
      "/assets/mixed/pantry.jpg",

      "/assets/catA/reception.jpg",
      "/assets/catA/lounge.jpg",
      "/assets/catA/officeNW.jpg",
      "/assets/catA/officeSW.jpg",
      "/assets/catA/pantry.jpg",

      "/assets/15th/elevators.webp",
      "/assets/15th/north.webp",
      "/assets/15th/northEast.webp",
      "/assets/15th/northWest.webp",
      "/assets/15th/south.webp",
      "/assets/15th/southEast.webp",
      "/assets/15th/southWest.webp",
    ],
    hotspots: [
      [
        {
          position: [-20, 0, -300],
          changeTo: 3,
          reference: useRef(),
        },
        {
          position: [-300, 0, 0],
          changeTo: 4,
          reference: useRef(),
        },
        {
          position: [-20, 0, 300],
          changeTo: 1,
          reference: useRef(),
        },
      ],
      [
        {
          position: [-350, 0, -150],
          changeTo: 4,
          reference: useRef(),
        },
        {
          position: [300, 0, -200],
          changeTo: 0,
          reference: useRef(),
        },
      ],
      [
        {
          position: [300, 0, 30],
          changeTo: 3,
          reference: useRef(),
        },
        {
          position: [70, 0, 300],
          changeTo: 4,
          reference: useRef(),
        },
      ],
      [
        {
          position: [-300, 0, 0],
          changeTo: 2,
          reference: useRef(),
        },
        {
          position: [-200, 0, 250],
          changeTo: 0,
          reference: useRef(),
        },
      ],
      [
        {
          position: [0, 0, -300],
          changeTo: 2,
          reference: useRef(),
        },
        {
          position: [300, 0, 250],
          changeTo: 1,
          reference: useRef(),
        },
        {
          position: [300, 0, -80],
          changeTo: 0,
          reference: useRef(),
        },
      ],

      [
        {
          position: [-300, 0, -1],
          changeTo: 7,
          reference: useRef(),
        },
        {
          position: [-110, 0, 300],
          changeTo: 6,
          reference: useRef(),
        },
      ],
      [
        {
          position: [-300, 0, -180],
          changeTo: 5,
          reference: useRef(),
        },
        {
          position: [-230, 0, 200],
          changeTo: 9,
          reference: useRef(),
        },
      ],
      [
        {
          position: [300, 10, -30],
          changeTo: 5,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 9,
          reference: useRef(),
        },
      ],
      [
        {
          position: [300, 0, -150],
          changeTo: 9,
          reference: useRef(),
        },
      ],
      [
        {
          position: [200, 0, -300],
          changeTo: 6,
          reference: useRef(),
        },
        {
          position: [-300, 0, -350],
          changeTo: 7,
          reference: useRef(),
        },
        {
          position: [-300, 0, -20],
          changeTo: 8,
          reference: useRef(),
        },
      ],

      [
        {
          position: [-300, 0, 0],
          changeTo: 14,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 13,
          reference: useRef(),
        },
        {
          position: [-20, 0, -300],
          changeTo: 11,
          reference: useRef(),
        },
      ],
      [
        {
          position: [-300, 0, 30],
          changeTo: 12,
          reference: useRef(),
        },
        {
          position: [20, 0, 300],
          changeTo: 10,
          reference: useRef(),
        },
      ],
      [
        {
          position: [300, 0, 30],
          changeTo: 11,
          reference: useRef(),
        },
        {
          position: [45, 0, 300],
          changeTo: 14,
          reference: useRef(),
        },
      ],
      [
        {
          position: [-170, 0, -200],
          changeTo: 14,
          reference: useRef(),
        },
        {
          position: [170, 0, -200],
          changeTo: 10,
          reference: useRef(),
        },
      ],
      [
        {
          position: [200, 0, 100],
          changeTo: 10,
          reference: useRef(),
        },
        {
          position: [0, 0, -300],
          changeTo: 12,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 13,
          reference: useRef(),
        },
      ],
      //---- 15th
      [
        {
          position: [300, 0, 0],
          changeTo: 19,
          reference: useRef(),
        },
        {
          position: [-300, 0, 0],
          changeTo: 16,
          reference: useRef(),
        },
      ],
      [
        {
          position: [300, 0, 0],
          changeTo: 19,
          reference: useRef(),
        },
        {
          position: [0, 0, -300],
          changeTo: 18,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 17,
          reference: useRef(),
        },
      ],
      //NE
      [
        {
          position: [300, 0, 0],
          changeTo: 20,
          reference: useRef(),
        },
        {
          position: [0, 0, -200],
          changeTo: 16,
          reference: useRef(),
        },
      ],
      //NW
      [
        {
          position: [300, 0, 0],
          changeTo: 21,
          reference: useRef(),
        },
        {
          position: [0, 0, 200],
          changeTo: 16,
          reference: useRef(),
        },
      ],
      //S
      [
        {
          position: [300, 0, 0],
          changeTo: 15,
          reference: useRef(),
        },
        {
          position: [0, 0, -300],
          changeTo: 20,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 21,
          reference: useRef(),
        },
      ],
      //SE
      [
        {
          position: [-200, 0, 0],
          changeTo: 17,
          reference: useRef(),
        },
        {
          position: [0, 0, -200],
          changeTo: 19,
          reference: useRef(),
        },
      ],
      //SW
      [
        {
          position: [-200, 0, 0],
          changeTo: 18,
          reference: useRef(),
        },
        {
          position: [0, 0, 300],
          changeTo: 19,
          reference: useRef(),
        },
      ],
    ],
  };

  return data;
}

export default useHotSpots;
